<template>
<!--  items-center  -->
  <div id="page-register"  class="h-screen vx-row w-full lg:bg-img flex no-gutter md:items-center lg:my-10 justify-center">
    <div class="vx-col w-full md:w-1/2 lg:w-1/2">
      <vx-card class="shadow-none md:shadow-lg">
        <img src="@/assets/images/logo/logo.png" alt="login" width="120px" class="mx-auto mb-5 round shadow-lg icon-animation" />

        <vs-divider class="mb-6 text-xl" color="grey">ลงทะเบียน</vs-divider>

        <div class="mt-5">
          <form-wizard
            color="rgba(var(--vs-primary), 1)"
            errorColor="rgba(var(--vs-danger), 1)"
            :title="null"
            :subtitle="null"
            :finishButtonText="isEdit? 'แก้ไข' : 'ลงทะเบียน' "
            back-button-text="ย้อนกลับ"
            next-button-text="ถัดไป"
          >
          <!-- tab 1  -->
          <tab-content
              title="ข้อมูลส่วนตัว"
              class="mb-5"
              icon="feather icon-user"
              :before-change="validateStep1"
            >
              <form data-vv-scope="step-1">
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="flex">
                      <div class="text-center ml-auto mr-10 radio-item" @click="form.registerData.gender='M'">
                        <img :class="form.registerData.gender=='M'?'border-solid':'border-dotted'" class=" round lg:p-3 p-2" src="@/assets/images/elements/male.png" width="80px" alt="" srcset="">
                         <p class="mt-2">ชาย</p>
                      </div>
                      <div class="text-center mr-auto radio-item"  @click="form.registerData.gender='F'">
                        <img :class="form.registerData.gender=='F'?'border-solid':'border-dotted'"  class="round lg:p-3 p-2" src="@/assets/images/elements/female.png" width="80px" alt="" srcset="">
                         <p class="mt-2">หญิง</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-full my-4">
                    <vs-input
                      size="large"
                      @keypress="checkName($event)"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength = "20"
                      placeholder="ชื่อ"
                      v-model="form.registerData.firstname"
                      class="w-full"
                      name="firstname"
                      v-validate="'required'"
                    />
                    <span class="text-danger">{{ errors.first('step-1.firstname') }}</span>
                  </div>
                </div>
                 <div class="vx-row">
                  <div class="vx-col w-full my-4">
                    <vs-input
                      size="large"
                      @keypress="checkName($event)"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength = "20"
                      placeholder="นามสกุล"
                      v-model="form.registerData.lastname"
                      class="w-full"
                      name="lastname"
                      v-validate="'required'"
                    />
                    <span class="text-danger">{{ errors.first('step-1.lastname') }}</span>
                  </div>
                </div>
                 <div class="vx-row">
                  <div class="vx-col w-full my-4">
                    <input
                      placeholder="เบอร์โทร"
                      class="vs-inputx vs-input--input large my-2 text-1xl"
                      v-model="form.registerData.phone"
                      name="phone"
                      v-mask="'### ### ####'"
                    />
                    <span class="text-danger">{{ errors.first('step-1.phone') }}</span>
                  </div>
                </div>
              </form>
            </tab-content>

            <!-- Tab 2 -->
            <tab-content
              title="ทะเบียนรถ"
              class="mb-5"
              icon="feather icon-square"
              :before-change="validateStep2"
            >
              <form data-vv-scope="step-2">
                <div class="vx-row mb-4">
                  <div class="vx-col w-full ">
                    <div class="flex">
                      <div class="text-center ml-auto mr-10 radio-item" @click="vehicleType='car'">
                        <img :class="vehicleType=='car'?'border-solid':'border-dotted'" class=" round lg:p-3 p-2" src="@/assets/images/elements/car.png" width="80px" alt="" srcset="">
                         <p class="mt-2">รถยนต์</p>
                      </div>
                      <div class="text-center mr-auto radio-item"  @click="vehicleType='motorcycle'">
                        <img :class="vehicleType!='car'?'border-solid':'border-dotted'"  class="round lg:p-3 p-2" src="@/assets/images/elements/motorcycle.png" width="80px" alt="" srcset="">
                         <p class="mt-2">รถมอเตอร์ไซต์</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 sm:w-1/2 w-1/2 mt-5">
                    <vs-input
                      size="large"
                      @keypress="checkPlaceNumber($event)"
                      label="เลขชุดหน้า"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength = "3"
                      placeholder="กข"
                      v-model="firstNum"
                      class="w-full"
                      name="firstNum"
                      v-validate="'required|max:3'"
                    />
                    <span class="text-danger">{{ errors.first('step-2.firstNum') }}</span>
                  </div>
                  <div class="vx-col md:w-1/2 sm:w-1/2 w-1/2 mt-5">
                    <vs-input
                      size="large"
                      label="เลขชุดหลัง"
                      @keypress="checkPlaceNumber($event)"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength = "4"
                      placeholder="9999"
                      v-model="secondNum"
                      class="w-full"
                      name="secondNum"
                      v-validate="'required|max:4'"
                    />
                    <span class="text-danger">{{ errors.first('step-2.secondNum') }}</span>
                  </div>
                </div>

                <vehicle-place :firstNum="firstNum" :secondNum="secondNum" :vehicleType="vehicleType" :vehiclePlateProvince="vehiclePlateProvince.title||vehiclePlateProvince" />

                <div class="vx-row">
                   <div class="vx-col lg:w-1/2 md:w-full m-auto w-full mt-5">
                    <label>จังหวัด</label>
                    <v-select
                      size="large"
                      placeholder="เลือกจังหวัด"
                      :disabled="firstNum==''||secondNum==''"
                      label="title"
                      :options="provinces"
                      v-validate="'required'"
                      v-model="vehiclePlateProvince"
                      name="vehiclePlateProvince"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          ไม่พบข้อมูล <em>"{{ search }}"</em>
                        </template>
                        <em style="opacity: 0.5;" v-else>กำลังโหลดข้อมูล</em>
                      </template>
                    </v-select>
                    <span class="text-danger">{{ errors.first('step-2.vehiclePlateProvince') }}</span>
                  </div>
                </div>
              </form>
            </tab-content>

            <!-- tab 3 -->
            <tab-content
              title="ยี่ห้อและรุ่น"
              class="mb-5"
              icon="feather icon-truck"
              :before-change="validateStep3"
            >
              <form data-vv-scope="step-3">
                <!-- Brands logo -->
                <div class="h-auto m-auto mb-5" style="width:120px" >
                  <div v-if="brand && logoUrlPreview">
                    <img class="w-full" :src="logoUrlPreview" alt="logoUrlPreview">
                  </div>
                  <div v-else>
                    <img class="border-dotted round lg:p-3 p-3 border-2" :src="vehicleType=='car' ? logoCarDefault : logoMotorcycleDefault" width="120px" alt="logo default" >
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col m-auto w-full">
                    <label>ยี่ห้อ</label>
                    <v-select
                      size="large"
                      placeholder="เลือกยี่ห้อ"
                      label="title_en"
                      :options="brands"
                      v-validate="'required'"
                      v-model="brand"
                      name="brand"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          ไม่พบข้อมูล <em>"{{ search }}"</em>
                        </template>
                        <em style="opacity: 0.5;" v-else>กำลังโหลดข้อมูล</em>
                      </template>
                    </v-select>
                    <span class="text-danger">{{ errors.first('step-3.brand') }}</span>
                  </div>
                </div>
                <div class="vx-row mt-5" v-if="vehicleType=='car'">
                  <div class="vx-col m-auto w-full">
                    <label>รุ่น</label>
                    <v-select
                      placeholder="เลือกรุ่น"
                      label="title_en"
                      :options="models"
                      v-validate="'required'"
                      v-model="model"
                      name="model"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          ไม่พบข้อมูล <em>"{{ search }}"</em>
                        </template>
                        <em style="opacity: 0.5;" v-else>กำลังโหลดข้อมูล</em>
                      </template>
                    </v-select>
                    <span class="text-danger">{{ errors.first('step-3.model') }}</span>
                  </div>
                </div>
              </form>
            </tab-content>

            <!-- tab 4 -->
            <tab-content
              title="พรบ./ประกันภัยและระยะตรวจสภาพ"
              class="mb-5"
              icon="feather icon-briefcase"
              :before-change="validateStepFinal"
            >
              <form data-vv-scope="step-4">
                <div class="vx-row">
                  <div class="vx-col m-auto w-full">
                    <label>วันสิ้นสุดระยะเวลาเอาประกันภัย พรบ. (ภาคบังคับ)</label>
                    <datepicker class="large" size="large" format="yyyy-MM-dd" placeholder="เลือกวันที่ (ถ้ามี)" v-model="compulsoryInsuranceCoverageEndDate"></datepicker>
                  </div>
                </div>
                <div class="vx-row  my-5">
                  <div class="vx-col m-auto w-full">
                    <vs-checkbox class="my-2 text-lg" v-model="isSameCompulsory"> วันเดียวกับ พรบ.</vs-checkbox>
                    <label>วันครบกำหนดเสียภาษี/ป้ายวงกลม</label>
                    <datepicker format="yyyy-MM-dd" placeholder="เลือกวันที่ (ถ้ามี)" v-model="payTaxDueDate"></datepicker>
                   </div>
                </div>
                <div class="vx-row my-5">
                  <div class="vx-col m-auto w-full">
                    <label>บริษัทประกันภัยรถยนต์ที่ใช้ (ภาคสมัครใจ)</label>
                    <v-select
                      placeholder="เลือกบริษัท (ถ้ามี)"
                      label="title"
                      :options="insuranceCompanies"
                      v-model="voluntaryInsuranceCompany"
                      name="voluntaryInsuranceCompany"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          ไม่พบข้อมูล <em>"{{ search }}"</em>
                        </template>
                        <em style="opacity: 0.5;" v-else>กำลังโหลดข้อมูล</em>
                      </template>
                    </v-select>
                    <span class="text-danger">{{ errors.first('step-4.voluntaryInsuranceCompany') }}</span>
                  </div>
                </div>
                <div class="vx-row my-5">
                  <div class="vx-col m-auto w-full">
                    <label>วันสิ้นสุดระยะเวลาเอาประกันภัย (ภาคสมัครใจ)</label>
                    <datepicker format="yyyy-MM-dd" placeholder="เลือกวันที่ (ถ้ามี)" v-model="voluntaryInsuranceCoverageEndDate"></datepicker>
                  </div>
                </div>
                <div class="vx-row my-5">
                  <div class="vx-col m-auto w-full">
                    <label>เลขไมล์ในการตรวจสภาพครั้งถัดไป</label>
                     <vs-input
                        size="large"
                        @keypress="numericInput($event)"
                        maxlength = "6"
                        placeholder="เลขไมล์ (ถ้ามี)"
                        v-model="nextMileNumber"
                        class="w-full"
                      />
                   </div>
                </div>
              </form>
            </tab-content>
          </form-wizard>
        </div>
      </vx-card>
    </div>

    <!-- Dialog Promp  -->
    <!-- <vs-prompt
      title=''
      accept-text='ยืนยัน'
      cancel-text='ยกเลิก'
      @accept="acceptRegister"
      :is-valid="isValidAuthorizationCode"
      :active.sync="activePrompt">
      <div class="con-exemple-prompt">
      <label class="text-3xl">รหัสลงทะเบียน</label>
      <input-mask :style="{fontSize: '50px!important'}" class="vs-inputx vs-input--input normal my-2 text-1xl text-center" v-model="authorizationCode" mask="***-***-***" ></input-mask>
      <vs-alert :active="authorizationCode!=''&&!isValidAuthorizationCode" color="danger" vs-icon="new_releases" >
        กรุณากรอกรหัสลงทะเบียนให้ครบ 9 หลัก
      </vs-alert>
      </div>
    </vs-prompt> -->

  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VehiclePlace from "./components/VehiclePlace";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import jwt from "jsonwebtoken";

// For custom error message
import { Validator } from "vee-validate";
const dict = {
  custom: {
    firstname: {
      required: "กรุณากรอกชื่อ",
    },
    lastname: {
      required: "กรุณากรอกสกุล",
    },
    phone: {
      required: "กรุณากรอกเบอร์โทร",
    },
    firstNum: {
      required: "กรุณากรอกเลขหน้า",
    },
    secondNum: {
      required: "กรุณากรอกเลขหลัง",
    },
    vehiclePlateProvince: {
      required: "กรุณาเลือกจังหวัด",
    },
    brand: {
      required: "กรุณาเลือกยี่ห้อ",
    },
    model: {
      required: "กรุณาเลือกรุ่น",
    },
    voluntaryInsuranceCompany: {
      required: "กรุณาเลือกบริษัทประกันภัย",
    },
  },
};

// register custom messages
Validator.localize("en", dict);

export default {
  name: "register",
  components: {
    VehiclePlace,
    FormWizard,
    TabContent,
    "v-select": vSelect,
    Datepicker,
  },
  mounted() {},
  data() {
    return {
      isSameCompulsory: false,
      logoCarDefault: require("@/assets/images/elements/car.png"),
      logoMotorcycleDefault: require("@/assets/images/elements/motorcycle.png"),
      isInit: true,
      isEdit: false,
      registerId: null,
      token: "",
      value: "",
      form: {
        registerData: {
          firstname: "",
          lastname: "",
          gender: "M",
          phone: "",
        },
      },
      vehicleType: "car",
      firstNum: "",
      secondNum: "",
      vehiclePlateNumber: "",
      vehiclePlateProvince: "",
      brand: "",
      model: "",
      nextMileNumber: null,
      compulsoryInsuranceCoverageEndDate: "",
      payTaxDueDate: "",
      voluntaryInsuranceCompany: "",
      voluntaryInsuranceCoverageEndDate: "",
      activePrompt: true,
      authorizationCode: "",
    };
  },
  created() {
    this.getBrands({ isActive: 1, isCar: 1, sort: 'priority:asc' });
    this.getProvinces();
    this.getInsuranceCompanies();

    if (this.$route.query.token) {
      this.token = this.$route.query.token;
      this.authorizationCode = this.$route.query.authorizationCode;
      this.setBearer({ accessToken: this.token });
      let decoded = jwt.decode(this.token);
      if (decoded) {
        this.getCustomerInfo(decoded.id);
        if (this.$route.query.id) {
          this.registerId = this.$route.query.id;
          this.$store
            .dispatch("customer/getRegister", {
              customerId: decoded.id,
              registerId: this.registerId,
            })
            .then((response) => {
              this.isEdit = true;
              this.editForm(response.data);
            })
            .catch((error) => this.errorAlert(error));
        }
      } else {
        this.$router.push({ name: "access-denied" });
      }
    } else {
      this.$router.push({ name: "access-denied" });
    }
  },
  watch: {
    compulsoryInsuranceCoverageEndDate() {
      if (this.compulsoryInsuranceCoverageEndDate && this.isSameCompulsory) {
        this.payTaxDueDate = this.compulsoryInsuranceCoverageEndDate;
      }
    },
    isSameCompulsory() {
      if (this.compulsoryInsuranceCoverageEndDate) {
        if (this.isSameCompulsory) {
          this.payTaxDueDate = this.compulsoryInsuranceCoverageEndDate;
        } else {
          this.payTaxDueDate = "";
        }
      }
    },
    brands() {
      let _brand = this.brands.find(
        (v) => v.title_en === (this.brand.title_en || this.brand)
      );
      this.logoUrlPreview = _brand && _brand.logoUrl
    },
    brand() {
      if (this.vehicleType === "car" && this.brand.id) {
        this.getModels({ isActive: 1, brand_id: this.brand.id, sort: 'title_en:asc' });
        this.model = ''
      } else {
        this.$store.dispatch("masterData/setModelsEmpty");
      }
    },
    vehicleType() {
      this.getBrands(
        this.vehicleType === "car" ? { isCar: 1 } : { isMotorcycle: 1 }
      );
      if (!this.isInit) {
        this.brand = "";
        this.model = "";
      }
    },
  },
  computed: {
    ...mapGetters({
      masterDatas: "masterData/masterDatas",
      customerInfo: "customer/customerInfo",
    }),
    provinces() {
      return this.masterDatas.provinces.rows || []
    },
    models() {
      return this.masterDatas.models.rows || []
    },
    brands() {
      return  this.masterDatas.brands.rows || []
    },
    insuranceCompanies() {
      return Array.isArray(this.masterDatas.insuranceCompanies.rows)
        ? [{ title: "ไม่มี" }, ...this.masterDatas.insuranceCompanies.rows]
        : [];
    },
    isLoading() {
      return (
        this.customerInfo.registers == null &&
        this.customerInfo.registers == undefined
      );
    },
    isValidAuthorizationCode() {
      return this.authorizationCode.replace(/[-_]/g, "").length == 9;
    },
  },
  methods: {
    ...mapMutations({
      setBearer: "auth/SET_LOCAL_STORAGE",
    }),
    ...mapActions({
      getRegister: "customer/getRegister",
      getCustomerInfo: "customer/getCustomerInfo",
      getBrands: "masterData/getBrands",
      getModels: "masterData/getModels",
      getInsuranceCompanies: "masterData/getInsuranceCompanies",
      getProvinces: "masterData/getProvinces",
    }),
    openLoading() {
      this.$vs.loading({
        type: "radius",
        container: "#div-loading",
        scale: 1,
      });
    },
    closeLoading() {
      this.$vs.loading.close("#div-loading > .con-vs-loading");
    },
    setSelected(value) {
      this.vehiclePlateProvince = value.title;
    },
    validateStep1() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-1").then((result) => {
          if (result) {
            this.isInit = false;
            resolve(true);
          } else {
            reject("correct all values");
          }
        });
      });
    },
    validateStep2() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-2").then((result) => {
          if (result) {
            if (this.vehicleType != "car") this.getBrands({ isMotorcycle: 1 });
            resolve(true);
          } else {
            reject("correct all values");
          }
        });
      });
    },
    validateStep3() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-3").then((result) => {
          if (result) {
            resolve(true);
          } else {
            reject("correct all values");
          }
        });
      });
    },
    validateStepFinal() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-4").then((result) => {
          if (result) {
            this.setForm();
            if (this.isEdit) {
              this.updateRegister();
            } else {
              this.acceptRegister();
            }
            resolve(true);
          } else {
            reject("correct all values");
          }
        });
      });
    },
    setForm() {
      this.form.registerData.phone = this.form.registerData.phone.replace(
        / /g,
        ""
      );
      this.form.registeredCar = {
        nextMileNumber: this.nextMileNumber,
        vehicleType: this.vehicleType,
        vehiclePlateNumber: `${this.firstNum} ${this.secondNum}`,
        vehiclePlateProvince:
          this.vehiclePlateProvince.title || this.vehiclePlateProvince,
        brand: this.brand.title_en || this.brand,
        model: this.model.title_en || this.model,
      };
      this.form.registeredInsurance = {
        voluntaryInsuranceCompany: this.voluntaryInsuranceCompany
          ? this.voluntaryInsuranceCompany.title ||
            this.voluntaryInsuranceCompany
          : null,
      };
      if (this.compulsoryInsuranceCoverageEndDate) {
        let _d = new Date(this.compulsoryInsuranceCoverageEndDate);
        this.form.registeredInsurance.compulsoryInsuranceCoverageEndDate = `${_d.getFullYear()}-${(
          "00" +
          (_d.getMonth() + 1)
        ).slice(-2)}-${("00" + _d.getDate()).slice(-2)}`;
      }
      if (this.payTaxDueDate) {
        let _d = new Date(this.payTaxDueDate);
        this.form.registeredInsurance.payTaxDueDate = `${_d.getFullYear()}-${(
          "00" +
          (_d.getMonth() + 1)
        ).slice(-2)}-${("00" + _d.getDate()).slice(-2)}`;
      }
      if (this.voluntaryInsuranceCoverageEndDate) {
        let end = new Date(this.voluntaryInsuranceCoverageEndDate);
        this.form.registeredInsurance.voluntaryInsuranceCoverageEndDate = `${end.getFullYear()}-${(
          "00" +
          (end.getMonth() + 1)
        ).slice(-2)}-${("00" + end.getDate()).slice(-2)}`;
      }
    },
    numericInput(evt) {
      evt = evt ? evt : window.event;
      let regex = new RegExp("^[0-9]+$");
      let str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
    },
    checkName(evt) {
      // Allow Eng, Thai and numeric
      evt = evt ? evt : window.event;
      let regex = new RegExp("^[\u0E00-\u0E7Fa-zA-Z0-9]+$");
      let str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      let specialCharacters = /[ `!@#$%^&*()__+/\-=[\]{};':"\\|,.<>?~]/;
      if (specialCharacters.test(str) || !regex.test(str)) {
        evt.preventDefault();
      }
      return true;
    },
    checkPlaceNumber(evt) {
      // Allow Eng, Thai and numeric
      evt = evt ? evt : window.event;
      let regex = new RegExp("^[ก-ฮa-zA-Z0-9]+$");
      let str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      let specialCharacters = /[ `!@#$%^&*()__+/\-=[\]{};':"\\|,.<>?~]/;
      if (specialCharacters.test(str) || !regex.test(str)) {
        evt.preventDefault();
      }
      return true;
    },
    updateRegister() {
      this.$store
        .dispatch("customer/updateRegister", {
          customerId: this.customerInfo.id,
          registerId: this.registerId,
          body: { ...this.form, authorizationCode: this.authorizationCode },
        })
        .then(() => {
          this.successAlert("แก้ไขข้อมูลเรียบร้อยแล้ว");
        })
        .catch((error) => {
          console.log(error);
          let msg = "เกิดข้อผิดพลาด กรุณาลองใหม่";
          this.errorAlert(msg);
        });
    },
    acceptRegister() {
      this.$store
        .dispatch("customer/register", {
          customerId: this.customerInfo.id,
          body: { ...this.form, authorizationCode: this.authorizationCode },
        })
        .then(() => {
          this.successAlert("ลงทะเบียนสำเร็จแล้ว");
        })
        .catch((error) => {
          let msg = "เกิดข้อผิดพลาด กรุณาลองใหม่";
          if (error.code == 4001) {
            msg = "รหัสลงทะเบียนไม่ถูกต้อง กรุณาลองใหม่";
          } else if (error.code == 4002) {
            msg = "รหัสลงทะเบียนนี้ถูกใช้ในระบบแล้ว กรุณาติดต่อ Admin";
          }
          this.errorAlert(msg);
        })
        .finally(() => (this.authorizationCode = ""));
    },
    successAlert(text = "", title = "สำเร็จ") {
      this.$vs.dialog({
        acceptText: "ตกลง",
        color: "success",
        title: title,
        text: text,
        accept: () => {
          this.$router.push({
            path: "information",
            query: { token: this.token },
          });
        },
      });
    },
    errorAlert(text = "", title = "แจ้งเตือน") {
      this.$vs.dialog({
        acceptText: "ตกลง",
        color: "danger",
        title: title,
        text: text,
      });
    },
    editForm(data) {
      this.form.registerData.firstname = data.firstname;
      this.form.registerData.lastname = data.lastname;
      this.form.registerData.gender = data.gender;
      this.form.registerData.phone = data.phone;

      this.vehicleType = data.vehicleType;
      this.vehiclePlateProvince = data.vehiclePlateProvince;
      let plateNumberArr = data.vehiclePlateNumber.split(" ");
      this.firstNum = plateNumberArr[0];
      this.secondNum = plateNumberArr[1];

      this.brand = data.brand;
      this.model = data.model || "";

      this.compulsoryInsuranceCoverageEndDate =
        data.compulsoryInsuranceCoverageEndDate;
      this.payTaxDueDate = data.payTaxDueDate;
      this.voluntaryInsuranceCompany = data.voluntaryInsuranceCompany;
      this.voluntaryInsuranceCoverageEndDate =
        data.voluntaryInsuranceCoverageEndDate;
      this.nextMileNumber = data.nextMileNumber;

      if (
        this.compulsoryInsuranceCoverageEndDate &&
        this.payTaxDueDate == this.compulsoryInsuranceCoverageEndDate
      ) {
        this.isSameCompulsory = true;
      }
    },
  },
};
</script>

<style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
/*! rtl:begin:ignore */
.greet-user {
  position: relative;

  .decore-left {
    position: absolute;
    left: 0;
    top: 0;
  }
  .decore-right {
    position: absolute;
    right: 0;
    top: 0;
  }
}
html,
body {
  background: #e4e4e4 !important;
}
.radio-item {
  .border-solid {
    border: solid 5px #4c61ca;
    opacity: 1;
  }
  .border-dotted {
    border: solid 2px;
    opacity: 0.5;
  }
}

.v-select .vs__dropdown-toggle .vs__search,
[dir] .vdp-datepicker input::placeholder,
[dir] .vs-input--input.normal::placeholder {
  color: #9e9e9e;
  font-size: 12px;
  font-family: "Prompt", sans-serif;
}
[dir] .vue-form-wizard .wizard-btn {
  font-size: 1.2rem;
  font-weight: 300;
  font-family: "Prompt", sans-serif;
}
[dir] .vs__selected {
  padding: 2px 0.25em;
  font-size: 1.2rem;
}
.v-select .vs__dropdown-toggle .vs__search {
  font-size: 1.2rem;
}
.v-select {
  font-size: 1.2rem;
  font-weight: 400;
}
[dir] .vs--searchable .vs__dropdown-toggle {
  padding: 8px 5px;
}
.vs-input--placeholder {
  font-size: 1.2rem;
}
label {
  font-weight: 500;
  font-size: 1.2rem !important;
}
.vdp-datepicker input {
  padding: 15px 15px !important;
  font-size: 1.2rem;
}
[dir] .vdp-datepicker input::placeholder {
  font-size: 1.2rem;
  color: #999999;
}
*::placeholder {
  color: #9e9e9e;
  font-family: "Prompt", sans-serif;
}
@media (max-width: 444px) {
  html,
  body {
    background: #ffffff !important;
  }
}

@media (max-width: 576px) {
  .decore-left,
  .decore-right {
    width: 140px;
  }
}

/*! rtl:end:ignore */
.vs-con-loading__container {
  overflow: unset;
}
.div-loading {
  height: 250px;
}
.vue-form-wizard .wizard-btn {
  min-width: 120px !important;
}
.vs-dialog-text {
  font-size: 1.2rem !important;
}
</style>

